<template>
  <div class="p-3">
    <b-row class="align-items-center">
      <b-col md="5" class="mb-2">Event</b-col>
      <b-col md="5" class="mb-2"
        >Do Automation (Only Automation type Allow)</b-col
      >
      <template v-for="(fields, index) of automation">
        <b-col md="5" :key="'fields1-' + index">
          <InputSelect
            title=""
            name="type"
            isRequired
            class="mb-0"
            textField="name"
            valueField="name"
            v-model="fields.custom_object_event"
            :options="addOptions"
            disabled
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >Field
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col md="5" :key="'fields2-' + index">
          <InputSelect
            title=""
            name="type"
            class="mb-0"
            isRequired
            textField="name"
            valueField="id"
            v-model="fields.automation_id"
            :options="options"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="0"
                >-- Select Automation --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col cols="2" :key="'fields3-' + index" v-if="automation.length > 1">
          <b-button variant="custom-link" @click="deleteAutomation(index)">
            <font-awesome-icon icon="trash-alt" class="pointer" />
          </b-button>
        </b-col>
      </template>
      <b-col cols="12">
        <b-button
          variant="custom-link"
          @click="addAutomation"
          :disabled="options.every((el) => el.disabled)"
        >
          <font-awesome-icon icon="plus-square" class="pointer" /> Add
          Automation</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    data: { required: false },
  },
  data() {
    return {
      automation: [
        {
          custom_object_id: 0,
          custom_object_event: "Add",
          automation_id: 0,
        },
      ],
      options: [],
      addOptions: ["Add"],
    };
  },
  created() {
    this.getData();
    if (this.$route.params.id && this.data.length > 0)
      this.automation = this.data;
  },
  watch: {
    automation: {
      handler: function (newValue) {
        let opt = [];
        for (const o of this.options) {
          let check = newValue.find((el) => el.automation_id == o.id);
          if (check) o.disabled = true;
          else o.disabled = false;
          opt.push(o);
        }
        this.$nextTick(() => (this.options = opt));
      },
      deep: true,
    },
  },
  methods: {
    async getData() {
      const resp = await this.axios(`/CustomObject/list_automation_allow`);
      this.options = resp.data.detail;
      for (const a of this.automation) {
        if (!resp.data.detail.find((el) => el.id == a.automation_id))
          a.automation_id = 0;
      }
    },
    addAutomation() {
      this.automation.push({
        custom_object_id: 0,
        custom_object_event: "Add",
        automation_id: 0,
      });
    },
    deleteAutomation(index) {
      this.automation.splice(index, 1);
    },
  },
};
</script>

<style></style>
